import React, { useState } from 'react'
import fetch from 'isomorphic-unfetch'
import Modal from 'react-modal'
import ENV from '../../../env'

let form = {}
const url_registrar = ENV[process.env.NODE_ENV].REGISTRAR_DT
const url_dt = ENV[process.env.NODE_ENV].OBTENER_DT
const url_persona = ENV[process.env.NODE_ENV].OBTENER_PERSONA

const saveDT = async (history, setLoading, setOpenModal) => {
  setLoading(true)
  const {
    tipodocumento,
    nrodocumento,
    nombre,
    apellidopaterno,
    apellidomaterno,
    numerocontacto,
    nombreequipo,
    email
  } = form
  const body = JSON.stringify({
    tipodocumento: tipodocumento.value,
    nrodocumento: nrodocumento.value,
    nombre: nombre.value,
    apellidopaterno: apellidopaterno.value,
    apellidomaterno: apellidomaterno.value,
    numerocontacto: numerocontacto.value,
    nombreequipo: nombreequipo.value,
    email: email.value,
    goles: 0
  })
  const headers = {
    Authorization: sessionStorage.getItem('token'),
    'Content-Type': 'application/json'
  }
  try {
    const response = await fetch(url_registrar, {
      crossDomain: true,
      method: 'POST',
      body,
      headers
    })
    const data = await response.json()
    if (data.error === true) {
      setLoading(false)
      setOpenModal(true)
      return
    }
    sessionStorage.setItem('tipodocumento', tipodocumento.value)
    sessionStorage.setItem('nrodocumento', nrodocumento.value)
    sessionStorage.setItem('apellidomaterno', data.data.apellidomaterno)
    sessionStorage.setItem('apellidopaterno', data.data.apellidopaterno)
    sessionStorage.setItem('goles', data.data.goles)
    sessionStorage.setItem('iddt', data.data.iddt)
    sessionStorage.setItem('nombre', data.data.nombre)
    sessionStorage.setItem('nombreequipo', data.data.nombreequipo)
    history.push('/referenciadores')
  } catch (err) {
    console.error('Ups :( ', err)
  }
}

const getData = async (tipo, nro, setLoading, history) => {
  setLoading(true)
  try {
    const body = JSON.stringify({
      tipodocumento: tipo,
      nrodocumento: parseInt(nro, 10)
    })
    const headers = {
      Authorization: sessionStorage.getItem('token'),
      'Content-Type': 'application/json'
    }
    const response = await fetch(url_dt, {
      crossDomain: true,
      method: 'POST',
      body,
      headers
    })
    const data = await response.json()
    if (data.error === true) {
      setLoading(false)
      alert(data.msg)
      return
    }
    sessionStorage.setItem('tipodocumento', tipo)
    sessionStorage.setItem('nrodocumento', nro)
    sessionStorage.setItem('apellidomaterno', data.apellidomaterno)
    sessionStorage.setItem('apellidopaterno', data.apellidopaterno)
    sessionStorage.setItem('goles', data.goles)
    sessionStorage.setItem('iddt', data.iddt)
    sessionStorage.setItem('nombre', data.nombre)
    sessionStorage.setItem('nombreequipo', data.nombreequipo)
    history.push('/referenciadores')
  } catch (err) {
    console.error('Ups :( ', err)
  }
}

const Banner = ({ history, errors }) => {
  const [option, setOption] = useState('registrar')
  const [loading, setLoading] = useState(false)
  const [tipoDoc, setTipoDocumento] = useState('DNI')
  const [modalOpen, setOpenModal] = useState(false)
  const [validations, setValidation] = useState({
    nrodocumento: {
      error: false
    },
    nombre: {
      error: false
    },
    apellidopaterno: {
      error: false
    },
    apellidomaterno: {
      error: false
    },
    numerocontacto: {
      error: false
    },
    nombreequipo: {
      error: false
    },
    email: {
      error: false
    }
  })
  const handleSubmit = (e) => {
      e.preventDefault()
      if (option === 'registrar') {
        saveDT(history, setLoading, setOpenModal)
      } else {
        const { tipodocumento, nrodocumento } = form
        getData(tipodocumento.value, nrodocumento.value, setLoading, history)
      }
    },
    onChange = async (e) => {
      e.preventDefault()
      let nroDocumento = e.target.value
      const {
        tipodocumento,
        nrodocumento,
        nombre,
        apellidopaterno,
        apellidomaterno,
        numerocontacto,
        nombreequipo
      } = form

      if (
        tipodocumento.value === 'DNI' &&
        nroDocumento.length === 8 &&
        option === 'registrar'
      ) {
        const body = JSON.stringify({
          numeroDocumento: parseInt(nroDocumento)
        })
        const headers = {
          Authorization: sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
        try {
          const response = await fetch(url_persona, {
            crossDomain: true,
            method: 'POST',
            body,
            headers
          })
          const data = await response.json()
          if (data.error === true) {
            setLoading(false)
            alert(data.msg)
            return
          }
          nombre.value = data.data.nombres
          apellidopaterno.value = data.data.apellidoPaterno
          apellidomaterno.value = data.data.apellidoMaterno
        } catch (err) {
          console.error('Ups :( ', err)
        }
      }
    }
  const setDocumento = () => {
    const { tipodocumento } = form
    document.getElementsByName('nrodocumento')[0].value = ''
    setTipoDocumento(tipodocumento.value)
  }
  const onChangeValue = (evt) => {
    const name = evt.target.name
    const value = evt.target.value
    const newValidation = { ...validations }

    const hasLetter = /^[a-zA-ZñÑ]+$/
    const isNumber = /^\d+$/

    switch (name) {
      case 'nrodocumento':
        if (!isNumber.test(value)) {
          newValidation.nrodocumento.error = true
        } else {
          newValidation.nrodocumento.error = false
        }
        setValidation(newValidation)
        break
      case 'nombre':
        if (!hasLetter.test(value)) {
          newValidation.nombre.error = true
        } else {
          newValidation.nombre.error = false
        }
        setValidation(newValidation)
        break
      case 'apellidopaterno':
        if (!hasLetter.test(value)) {
          newValidation.apellidopaterno.error = true
        } else {
          newValidation.apellidopaterno.error = false
        }
        setValidation(newValidation)
        break
      case 'apellidomaterno':
        if (!hasLetter.test(value)) {
          newValidation.apellidomaterno.error = true
        } else {
          newValidation.apellidomaterno.error = false
        }
        setValidation(newValidation)
        break
      case 'numerocontacto':
        if (!isNumber.test(value)) {
          newValidation.numerocontacto.error = true
        } else {
          newValidation.numerocontacto.error = false
        }
        setValidation(newValidation)
        break
      default:
        return null
    }
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  }
  const closeModal = () => {
    setOpenModal(false)
  }
  return (
    <React.Fragment>
      <section id='banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-banner-l'>
              <div className='title'>
                <h3 className='title__colors'>
                  Conviértete en DT y <span>viaja a Brasil</span> a ver a la
                  selección
                </h3>
                <h2 className='title__principal fixMainTitleBanner'>
                  ¡Gana un paquete doble SIN SORTEOS… y muchos premios
                  inmediatos
                </h2>
              </div>
              <div className='formContentBrasil'>
                <div className='rowTabsOption'>
                  <div
                    onClick={() => setOption('registrar')}
                    className={`rowTabsOption--tab ${
                      option === 'registrar'
                        ? ' rowTabsOption--tab--active'
                        : ''
                    }`}
                  >
                    REGÍSTRATE
                  </div>
                  <div
                    onClick={() => setOption('equipo')}
                    className={`rowTabsOption--tab ${
                      option === 'equipo' ? ' rowTabsOption--tab--active' : ''
                    }`}
                  >
                    YA TENGO MI EQUIPO
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit}
                  ref={(e) => {
                    form = e
                  }}
                >
                  <div className='renderTabOption'>
                    <div className='renderTabOption--row renderTabOption--row--twoCols'>
                      <select
                        name='tipodocumento'
                        required
                        onChange={() => setDocumento()}
                      >
                        <option value='DNI'>DNI</option>
                        <option value='CARNET'>CARNET</option>
                      </select>
                      <input
                        required
                        onChange={onChange}
                        minLength={tipoDoc === 'CARNET' ? '1' : '8'}
                        maxLength={tipoDoc === 'CARNET' ? '12' : '8'}
                        pattern='\d*'
                        placeholder='Nro. de Documento'
                        name='nrodocumento'
                        type='text'
                        onChange={(e) => onChangeValue(e)}
                      />
                      {validations.nrodocumento.error && (
                        <div
                          className='customBorder--errorText'
                          style={{ top: '34px' }}
                        >
                          Debe ingresar un documento válido.
                        </div>
                      )}
                    </div>
                    {option === 'registrar' && (
                      <div>
                        <div className='renderTabOption--row'>
                          <input
                            required
                            className={
                              validations.nombre.error
                                ? 'customBorder customBorder--error'
                                : 'customBorder'
                            }
                            type={tipoDoc === 'CARNET' ? 'text' : 'hidden'}
                            name='nombre'
                            placeholder='Nombres'
                            onChange={(e) => onChangeValue(e)}
                          />
                          {validations.nombre.error && (
                            <div className='customBorder--errorText'>
                              Debe ingresar un nombre válido.
                            </div>
                          )}
                        </div>
                        <div className='renderTabOption--row'>
                          <input
                            required
                            className={
                              validations.apellidopaterno.error
                                ? 'customBorder customBorder--error'
                                : 'customBorder'
                            }
                            type={tipoDoc === 'CARNET' ? 'text' : 'hidden'}
                            name='apellidopaterno'
                            placeholder='Apellido paterno'
                            onChange={(e) => onChangeValue(e)}
                          />
                          {validations.apellidopaterno.error && (
                            <div className='customBorder--errorText'>
                              Debe ingresar un apellido paterno válido.
                            </div>
                          )}
                        </div>
                        <div className='renderTabOption--row'>
                          <input
                            required
                            className={
                              validations.apellidomaterno.error
                                ? 'customBorder customBorder--error'
                                : 'customBorder'
                            }
                            type={tipoDoc === 'CARNET' ? 'text' : 'hidden'}
                            name='apellidomaterno'
                            placeholder='Apellido materno'
                            onChange={(e) => onChangeValue(e)}
                          />
                          {validations.apellidomaterno.error && (
                            <div className='customBorder--errorText'>
                              Debe ingresar un apellido materno válido.
                            </div>
                          )}
                        </div>
                        <div className='renderTabOption--row'>
                          <input
                            required
                            className={
                              validations.numerocontacto.error
                                ? 'customBorder customBorder--error'
                                : 'customBorder'
                            }
                            name='numerocontacto'
                            minLength='9'
                            pattern='\d*'
                            type='text'
                            placeholder='Número de contacto'
                            onChange={(e) => onChangeValue(e)}
                          />
                          {validations.numerocontacto.error && (
                            <div className='customBorder--errorText'>
                              Debe ingresar un número válido.
                            </div>
                          )}
                        </div>
                        <div className='renderTabOption--row'>
                          <input
                            required
                            className='customBorder'
                            type='text'
                            name='nombreequipo'
                            placeholder='Nombre de tu equipo'
                            onChange={(e) => onChangeValue(e)}
                          />
                        </div>
                        <div className='renderTabOption--row'>
                          <input
                            type='email'
                            required
                            className='customBorder'
                            name='email'
                            placeholder='Email'
                            onChange={(e) => onChangeValue(e)}
                          />
                        </div>
                      </div>
                    )}
                    <button
                      className={`buttonDT${loading ? ' btn--loading' : ''}`}
                      type='submit'
                    >
                      {option === 'registrar' ? 'LISTO PARA SER DT' : 'VAMOS'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className='col-banner-r image-container' />
          </div>
        </div>
        <div
          className='bgHomeBanner'
          style={{
            backgroundImage: `url(${
              ENV[process.env.NODE_ENV].ASSETS_FOLDER
            }images/BannerPrincipal.png)`
          }}
        />
      </section>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='wrapModalRimac'>
          <div className='wrapModalRimac--close' onClick={() => closeModal()} />
          <div className='wrapModalRimac__title'>
            Tu convocado no puede entrar a la cancha
          </div>
          <div className='wrapModalRimac__content'>
            Como en el fútbol, un jugador solo tiene un equipo. La persona que
            estás convocando ya está jugando en otro equipo, por favor ingresa
            otro.
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Banner
