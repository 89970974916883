// Dependencias necesarias de la APP
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'

// Layout de la APP
import AppLayout from './components/layout'

// Páginas
import Inicio from './pages/inicio'
import Referenciadores from './pages/referenciadores'
import NotFound from './pages/404'

// Estilos de la APP
import '../assets/scss/main.scss'

// Limpio el storage para obtener todo otra vez :)
sessionStorage.clear()

const AppRoute = ({
  component: Component,
  layout: Layout,
  ...params // De ser necesario parametros personalizados a la vista, se los pasa
}) => (
  <Route
    {...params}
    render={(props) => {
      return (
        <Layout history={props.history}>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

const routes = (
  <BrowserRouter>
    <React.Fragment>
      <Switch>
        <AppRoute exact path='/' layout={AppLayout} component={Inicio} />
        <Route
          path='/referenciadores'
          render={(props) => {
            return sessionStorage.getItem('iddt') !== null ? (
              <AppLayout history={props.history}>
                <Referenciadores {...props} />
              </AppLayout>
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location }
                }}
              />
            )
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  </BrowserRouter>
)

ReactDOM.render(routes, document.getElementById('app'))

module.hot.accept()
