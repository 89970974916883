import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import ENV from '../../../env'

let form = {}

const url_dt = ENV[process.env.NODE_ENV].OBTENER_DT
const listar_convocados = ENV[process.env.NODE_ENV].LISTAR_CONVOCADOS
const registrar_convocados = ENV[process.env.NODE_ENV].REGISTRAR_CONVOCADOS
const url_persona = ENV[process.env.NODE_ENV].OBTENER_PERSONA

const Referenciadores = ({ history }) => {
  const [datoUsuario, setData] = useState({})
  const [datoConvocados, setConvocados] = useState({})
  const [tipoDoc, setTipoDocumento] = useState('DNI')
  const [loading, setLoading] = useState(false)
  const [collapseForm, setCollapse] = useState(false)
  const [modalOpen, setOpenModal] = useState(false)
  const [validations, setValidation] = useState({
    nrodocumento: {
      error: false
    },
    nombre: {
      error: false
    },
    apellidopaterno: {
      error: false
    },
    apellidomaterno: {
      error: false
    },
    numerocontacto: {
      error: false
    },
    nombreequipo: {
      error: false
    },
    email: {
      error: false
    }
  })

  const getData = async () => {
    try {
      const body = JSON.stringify({
        tipodocumento: sessionStorage.getItem('tipodocumento'),
        nrodocumento: parseInt(sessionStorage.getItem('nrodocumento'), 10)
      })
      const headers = {
        Authorization: sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
      const response = await fetch(url_dt, {
        crossDomain: true,
        method: 'POST',
        body,
        headers
      })
      const data = await response.json()
      setData(data)
    } catch (err) {
      console.error('Ups :( ', err)
    }
  }

  const getConvocados = async () => {
    try {
      const body = JSON.stringify({
        dt: parseInt(sessionStorage.getItem('nrodocumento'), 10)
      })
      const headers = {
        Authorization: sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
      const response = await fetch(listar_convocados, {
        crossDomain: true,
        method: 'POST',
        body,
        headers
      })
      const data = await response.json()
      setConvocados(data)
    } catch (err) {
      console.error('Ups :( ', err)
    }
  }

  const setConvocar = async (params) => {
    setLoading(true)
    try {
      const body = JSON.stringify({
        iddt: params.iddt,
        origen: '',
        origenMedio: '',
        origenContenido: '',
        origenCamapania: '',
        nombre: params.nombre,
        apellidopaterno: params.apellidopaterno,
        apellidomaterno: params.apellidomaterno,
        tipodocumento: params.tipodocumento,
        nrodocumento: params.nrodocumento,
        nrocontacto: params.numerocontacto,
        correo: params.correo,
        subproducto: params.subproducto
      })
      const headers = {
        Authorization: sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
      const response = await fetch(registrar_convocados, {
        crossDomain: true,
        method: 'POST',
        body,
        headers
      })
      const data = await response.json()
      if (data.error === true) {
        setLoading(false)
        setOpenModal(true)
        return
      }
      const newOBJ = { ...datoConvocados }
      newOBJ.data.push({
        nombres: data.data.nombre,
        producto: data.data.tipolead,
        estado: 'AGENDADO'
      })
      setConvocados(newOBJ)
      setLoading(false)
    } catch (err) {
      console.error('Ups :( ', err)
    }
  }

  useEffect(() => {
    getData()
    getConvocados()
  }, [])

  const handleSubmit = (e) => {
      e.preventDefault()
      const {
        tipodocumento,
        nrodocumento,
        nombre,
        apellidopaterno,
        apellidomaterno,
        numerocontacto,
        subproducto,
        correo
      } = form
      const json = {
        iddt: sessionStorage.getItem('iddt'),
        origen: '',
        origenMedio: '',
        origenContenido: '',
        origenCamapania: '',
        nombre: nombre.value,
        apellidopaterno: apellidopaterno.value,
        apellidomaterno: apellidomaterno.value,
        tipodocumento: tipodocumento.value,
        nrodocumento: nrodocumento.value,
        nrocontacto: numerocontacto.value,
        correo: correo.value,
        subproducto: subproducto.value
      }
      setConvocar(json)
    },
    onChange = async (e) => {
      e.preventDefault()
      let nroDocumento = e.target.value
      const {
        tipodocumento,
        nrodocumento,
        nombre,
        apellidopaterno,
        apellidomaterno,
        numerocontacto,
        nombreequipo
      } = form

      if (tipodocumento.value === 'DNI' && nroDocumento.length === 8) {
        const body = JSON.stringify({
          numeroDocumento: parseInt(nroDocumento)
        })
        const headers = {
          Authorization: sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
        try {
          const response = await fetch(url_persona, {
            crossDomain: true,
            method: 'POST',
            body,
            headers
          })
          const data = await response.json()
          if (data.error === true) {
            setLoading(false)
            alert(data.msg)
            return
          }
          nombre.value = data.data.nombres
          apellidopaterno.value = data.data.apellidoPaterno
          apellidomaterno.value = data.data.apellidoMaterno
        } catch (err) {
          console.error('Ups :( ', err)
        }
      }
    }
  const setDocumento = () => {
    const { tipodocumento } = form
    setTipoDocumento(tipodocumento.value)
  }
  const onChangeValue = (evt) => {
    const name = evt.target.name
    const value = evt.target.value
    const newValidation = { ...validations }

    const hasLetter = /^[a-zA-ZñÑ]+$/
    const isNumber = /^\d+$/

    switch (name) {
      case 'nrodocumento':
        if (!isNumber.test(value)) {
          newValidation.nrodocumento.error = true
        } else {
          newValidation.nrodocumento.error = false
        }
        setValidation(newValidation)
        break
      case 'nombre':
        if (!hasLetter.test(value)) {
          newValidation.nombre.error = true
        } else {
          newValidation.nombre.error = false
        }
        setValidation(newValidation)
        break
      case 'apellidopaterno':
        if (!hasLetter.test(value)) {
          newValidation.apellidopaterno.error = true
        } else {
          newValidation.apellidopaterno.error = false
        }
        setValidation(newValidation)
        break
      case 'apellidomaterno':
        if (!hasLetter.test(value)) {
          newValidation.apellidomaterno.error = true
        } else {
          newValidation.apellidomaterno.error = false
        }
        setValidation(newValidation)
        break
      case 'numerocontacto':
        if (!isNumber.test(value)) {
          newValidation.numerocontacto.error = true
        } else {
          newValidation.numerocontacto.error = false
        }
        setValidation(newValidation)
        break
      default:
        return null
    }
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  }
  const closeModal = () => {
    setOpenModal(false)
  }
  return (
    <div>
      <section id='referenciador'>
        <div
          className='referenciador__header'
          style={{
            backgroundImage: `url(${
              ENV[process.env.NODE_ENV].ASSETS_FOLDER
            }images/Convocados_Background.png)`
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col s2 link'>
                <div
                  onClick={() => history.goBack()}
                  id='btnBack'
                  className='link-back'
                  style={{ color: '#ffffff', cursor: 'pointer' }}
                >
                  <i className='icon-left-open' />
                  <span>Volver</span>
                </div>
              </div>
              <div className='col s2 img_dt_xd pc' />
              <div className='col s5 css_movil_5'>
                {Object.keys(datoUsuario).length > 0 && (
                  <React.Fragment>
                    <div className='camp_tituloDT'>
                      <div>
                        Hola {datoUsuario.nombre} {datoUsuario.apellidopaterno}{' '}
                        {datoUsuario.apellidomaterno}, eres DT del{' '}
                        <span style={{ color: '#F0353B' }}>
                          {datoUsuario.nombreequipo}
                        </span>{' '}
                        <a href='' className='camp_editarDT'>
                          EDITAR
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className='col s3'>
                <div className='seccion_comparte'>
                  Comparte tu convocatoria:
                  <div
                    className='seccion_comparte_redes'
                    style={{ marginTop: '5px' }}
                  >
                    <a
                      onClick={() => {
                        window.open(
                          'https://wa.me/?text=' +
                            'https://www.rimac.com.pe/goleadores'
                        )
                      }}
                      target='_blank'
                    >
                      <img
                        src={`${
                          ENV[process.env.NODE_ENV].ASSETS_FOLDER
                        }images/whatsapp.png`}
                        style={{ width: '25px', cursor: 'pointer' }}
                      />
                    </a>
                    <a
                      onClick={() => {
                        window.open(
                          'https://www.facebook.com/sharer/sharer.php?u=' +
                            'https://www.rimac.com.pe/goleadores',
                          'facebook-popup',
                          'height=350,width=600'
                        )
                      }}
                      target='_blank'
                    >
                      <img
                        src={`${
                          ENV[process.env.NODE_ENV].ASSETS_FOLDER
                        }images/feisbuk.png`}
                        style={{
                          marginLeft: '10px',
                          width: '25px',
                          cursor: 'pointer'
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container espacio'>
          <div className='row'>
            <div className='col s12 m6 l4 form-container'>
              <div className='renderTabOption'>
                <div className='seccion_convocar'>
                  {collapseForm
                    ? 'QUIERO SEGUIR CONVOCANDO'
                    : 'QUIERO CONVOCAR A:'}
                </div>
                <div
                  className='collapseForm'
                  onClick={() => setCollapse(!collapseForm)}
                />
                <form
                  className={collapseForm ? 'hide' : 'show'}
                  onSubmit={handleSubmit}
                  ref={(e) => {
                    form = e
                  }}
                >
                  <div className='renderTabOption--row renderTabOption--row--twoCols form_width'>
                    <select
                      name='tipodocumento'
                      required
                      onChange={() => setDocumento()}
                    >
                      <option value='DNI'>DNI</option>
                      <option value='CARNET'>CARNET</option>
                    </select>
                    <input
                      required
                      onChange={onChange}
                      minLength={tipoDoc === 'CARNET' ? '1' : '8'}
                      maxLength={tipoDoc === 'CARNET' ? '12' : '8'}
                      pattern='\d*'
                      placeholder='Nro. de Documento'
                      name='nrodocumento'
                      type='text'
                      onChange={(e) => onChangeValue(e)}
                    />
                    {validations.nrodocumento.error && (
                      <div
                        className='customBorder--errorText'
                        style={{ top: '34px' }}
                      >
                        Debe ingresar un documento válido.
                      </div>
                    )}
                  </div>
                  <div>
                    <div className='renderTabOption--row'>
                      <input
                        required
                        className='customBorder'
                        type={tipoDoc === 'CARNET' ? 'text' : 'hidden'}
                        name='nombre'
                        placeholder='Nombres'
                        onChange={(e) => onChangeValue(e)}
                      />
                      {validations.nombre.error && (
                        <div className='customBorder--errorText'>
                          Debe ingresar un nombre válido.
                        </div>
                      )}
                    </div>
                    <div className='renderTabOption--row'>
                      <input
                        required
                        className='customBorder'
                        type={tipoDoc === 'CARNET' ? 'text' : 'hidden'}
                        name='apellidopaterno'
                        placeholder='Apellido paterno'
                        onChange={(e) => onChangeValue(e)}
                      />
                      {validations.apellidopaterno.error && (
                        <div className='customBorder--errorText'>
                          Debe ingresar un apellido paterno válido.
                        </div>
                      )}
                    </div>
                    <div className='renderTabOption--row'>
                      <input
                        required
                        className='customBorder'
                        type={tipoDoc === 'CARNET' ? 'text' : 'hidden'}
                        name='apellidomaterno'
                        placeholder='Apellido materno'
                        onChange={(e) => onChangeValue(e)}
                      />
                      {validations.apellidomaterno.error && (
                        <div className='customBorder--errorText'>
                          Debe ingresar un apellido materno válido.
                        </div>
                      )}
                    </div>
                    <div className='renderTabOption--row'>
                      <input
                        required
                        className='customBorder'
                        type='text'
                        name='numerocontacto'
                        minLength='9'
                        pattern='\d*'
                        placeholder='Número de contacto'
                        onChange={(e) => onChangeValue(e)}
                      />
                      {validations.numerocontacto.error && (
                        <div className='customBorder--errorText'>
                          Debe ingresar un número válido.
                        </div>
                      )}
                    </div>
                    <div className='renderTabOption--row'>
                      <input
                        required
                        className='customBorder'
                        type='email'
                        name='correo'
                        placeholder='correo'
                        onChange={(e) => onChangeValue(e)}
                      />
                    </div>
                    <div className='renderTabOption--row'>
                      <select
                        className='customBorder'
                        name='subproducto'
                        required
                      >
                        <option selected value=''>
                          ¿Qué seguro desea?
                        </option>
                        <option value='seguro-oncologico'>
                          Seguro oncologico
                        </option>
                        <option value='accidentes-ninios-jovenes'>
                          Accidentes Niños y Jovenes
                        </option>
                        <option value='seguro-domiciliario'>
                          Seguro domiciliario
                        </option>
                        <option value='sepelio'>Sepelio</option>
                        <option value='cotizador-salud'>Seguro Salud</option>
                        <option value='cotizador-vehicular'>
                          Seguro Vehicular
                        </option>
                        <option value='seguro-viajes'>Seguro de Viaje</option>
                        <option value='vida-con-devolucion'>
                          Vida con Devolución
                        </option>
                        <option value='soat-digital'>SOAT Digital</option>
                        <option value='pymes'>Pymes</option>
                      </select>
                    </div>
                  </div>
                  <div className='texto_terminos'>
                    <p>
                      {' '}
                      Aceptar la{' '}
                      <a href='#' id='btnBack' className='link-back'>
                        Politica de Protección de Datos Personales
                      </a>{' '}
                      y{' '}
                      <a href='#' id='btnBack' className='link-back'>
                        Términos y Condiciones
                      </a>
                    </p>
                  </div>
                  <button
                    className={`buttonDT${loading ? ' btn--loading' : ''}`}
                    type='submit'
                  >
                    CONVOCAR
                  </button>
                </form>
                <div className={collapseForm ? 'hide' : 'show texto_inferior'}>
                  Recuerda avisar a tu convocado que nos contactaremos con
                  él/ella. Una vez que haya adquirido un seguro con nosotros,
                  vas sumando goles.
                </div>
              </div>
            </div>
            <div className='col s12 m6 l8'>
              {Object.keys(datoConvocados).length > 0 && (
                <div className='referidos'>
                  {datoConvocados.data.length > 0 ? (
                    <React.Fragment>
                      <div className='row'>
                        <div className='referidos__header list'>
                          <h4 className='col s12 l7 list__title titulo_convocados'>
                            Tus convocados ({datoConvocados.data.length})
                          </h4>
                        </div>
                      </div>
                      <div className='row referidos__body list row_complete'>
                        <div className='col s12 list__full'>
                          <div className='title-container titulo_lista'>
                            <ul className='col l11'>
                              <li className='col l4'>NOMBRE</li>
                              <li className='col l3'>PRODUCTO</li>
                              <li className='col l3'>ESTADO</li>
                              <li className='col l2'>GOLES</li>
                            </ul>
                          </div>
                          <div className='title-container'>
                            {datoConvocados.data.map((e) => {
                              return (
                                <ul className='col l11 dev_linea'>
                                  <li className='col l4'>{e.nombres}</li>
                                  <li className='col l3'>{e.producto}</li>
                                  <li className='col l3'>{e.estado}</li>
                                  <li className='col l2'>{e.goles}</li>
                                </ul>
                              )
                            })}
                          </div>
                        </div>
                        <div className='col s12 list__full margin_top45'>
                          <div className='col s8 camp_top'>
                            ¿Quieres saber en que puesto se encuentra tu equipo?
                          </div>
                          <div className='col s4 camp_top_ver'>
                            <a href=''>VER TOP 5 DE GOLEADORES</a>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='canchaVacia'>
                        <div className='canchaVacia__img'>
                          <img
                            src={`${
                              ENV[process.env.NODE_ENV].ASSETS_FOLDER
                            }images/cancha.png`}
                          />
                        </div>
                        <div className='canchaVacia__text'>
                          Aún la cancha está vacía.
                          <br />
                          ¡Empieza a convocar!
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='wrapModalRimac'>
          <div className='wrapModalRimac--close' onClick={() => closeModal()} />
          <div className='wrapModalRimac__title'>
            Tu convocado no puede entrar a la cancha
          </div>
          <div className='wrapModalRimac__content'>
            Como en el fútbol, un jugador solo tiene un equipo. La persona que
            estás convocando ya está jugando en otro equipo, por favor ingresa
            otro.
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Referenciadores
